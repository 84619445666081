import * as THREE from 'three';
import "./style.css"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

"use strict";

//scene
const scene = new THREE.Scene();


const loader = new GLTFLoader();
loader.load( '../resources/cartoon_earth/scene.gltf', function ( gltf ) {
	scene.add( gltf.scene );
}, undefined, function ( error ) {
	console.error( error );
} );

//create sphere
// const geometry = new THREE.SphereGeometry(3, 64, 64);
// const material = new THREE.MeshStandardMaterial({
//     color: "#00ff83"
// });
// const mesh = new THREE.Mesh(geometry, material);
// scene.add(mesh);


//sizing
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}


//add Camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100);
camera.position.z = 30;
scene.add(camera);

//light
const light = new THREE.PointLight(0xffffff, 0.6 , 100);
let update_light = () => light.position.set(camera.position.x + 20, camera.position.y - 10, camera.position.z);
scene.add(light);

//renderer
const renderer = new THREE.WebGLRenderer();
renderer.setSize(sizes.width, sizes.height);
renderer.render(scene, camera);
renderer.setPixelRatio(2)

renderer.domElement.setAttribute("class", "webgl") // assign the canvas to a class
document.body.appendChild( renderer.domElement );

// window resize listener
window.addEventListener('resize', () => {
    //update the window dimensions + camera
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    camera.aspect = sizes.width / sizes.height

    //updateProjectionMatrix ensures the object stays the same size when window is resized
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
})

//Controls
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;
controls.enablePan = false;
controls.enableZoom = false;
controls.autoRotate = true;

//no idea why this needs to be an arrow function rather than regular function
const loop = () => {
    controls.update()
    update_light();
    renderer.render(scene, camera);
    window.requestAnimationFrame(loop);
}
loop()


// <!-- <nav>
// <a href="/">Sphere</a>
// <ul>
//     <li>Explore</li>
//     <li>Create</li>
// </ul>
// </nav>
// <h1 class="title">give it a spin</h1> -->
